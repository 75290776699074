.btn-black {
    color: #fff;
    background-color: #000;
    border-color: #000;
	padding:5px;
}
.mgl-20 {
   margin-left:20px;
}
.mgt-5 {
   margin-top:60px;
}
.mgt-40 {
   margin-top:40px;
}
.mgt-60 {
	margin-top:60px;
}
.align-right {
   text-align:right;
}
