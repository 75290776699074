.fileuplaodlayer {
    background-color: #FFF !important;
    text-align:left;
	position:absolute !important;
	top:50% !important;
	right:0;
	bottom:0;
	left:0;
	height:100% !important;
}
.align-center {
  text-align:center;
}
.mgt-10 {
  margin-top:10px !important;
}
.pd-20 {
  padding:20px !important;
}

.btn-black {
    color: #fff;
    background-color: #000;
    border-color: #000;
	padding:5px;
}