@font-face {
  font-family: Gilroy;
  src: url(./assests/gilroy.otf);
}
@font-face {
  font-family: gilroy-light;
  src: url(./assests/Gilroy-Light.otf);
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: Gilroy !important;
  background-color:#9fb3cc !important;
  /*#c5d2e1*/
}
.card-header:first-child {
	border-radius:0px !important;
}
.card-footer:last-child {
	border-radius:0px !important;
}
.card{
	border-radius:0px !important;
	-webkit-box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.29);
       -moz-box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.29);
            box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.29);
}
.btn {
	padding: 7px 30px !important;
	border-radius: 2rem !important;
}
.btn:hover {
  box-shadow: -1px 10px 10px 0 rgba(120, 60, 20, .3);
}
.btn-primary {
    color: #fff;
    background-color: #8f2d2f !important;
    border-color:#8f2d2f !important;
}
.card-header {
	padding:10px 10px !important;
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {   
  box-shadow:none;
  outline: 0 none;
}