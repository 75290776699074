.options {
  display: grid;
  background: #fefefe;
}

@media (max-width: 600px) {
  .options {
    display: flex;
    flex-direction: column;
    max-width: 480px;
  }
}
.options > * {
  width: 100%;
}
.options .btn {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  transition: none;
}
.options .btn:hover {
  opacity: 0.9;
}
.options .btn:focus {
  outline: 2px solid #000;
}
.inputgroup {
  font-family: Gilroy-light;
  font-weight: bold;
  letter-spacing: 0.09rem;
}

.inputgroup {
  width: 100%;
  max-width: 360px;
}

.btnDiv {
  display: flex;
  margin-top: 1rem;
  flex-direction: row;
  justify-content: space-around;
}

.phone {
  height: 4rem;
}

.btnDiv .answer {
  background-color: #29bb89;
  color: #1e6f5c;
  font-weight: bold;
}

.btnDiv .decline {
  background-color: rgb(250, 30, 14, 0.75);
  color: #8c0000;
  font-weight: bold;
}
.hang {
  background-color: rgb(250, 30, 14, 0.75) !important;
  color: #8c0000 !important;
  font-weight: bold;
  margin-top: 0.5rem;
}

.share_options {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 0.5rem;
}
.share_social {
  display: flex;
  /* margin-top: 1rem; */
}
.share_icon {
  display: flex;
  margin-left: 0.4rem;
}
.callername_layer {
  font-size:18px !important
}

.hangup_btn {
  	line-height: 1.5715;
    position:absolute !important;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;    
    box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-user-select: none;
    -moz-user-select: none;    
    user-select: none;    
    height: 32px;
    padding: 4px 15px;
    font-size: 14px;
    border-radius: 2px;
    color: rgba(0, 0, 0, 0.85);    
    border-color: #d9d9d9;
	background-color: rgb(250, 30, 14) !important;
    color: #000 !important;
    font-weight: bold;
    margin-top: 0.5rem;
	color:#000 !important;
	bottom:3% !important;
	left:40% !important;
}
.align_center {
	text-align:center;
}

.hide {
	display:none !important;
}

.client_options_layer {
  position: absolute !important;
  bottom: 0 !important;
  left: 0 !important;
}