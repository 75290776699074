.footer {
    background: #c5cbc7;
    color: #444;
    border-top:1px solid #d2d6de;
    font-size:14px;
    min-height:25px;
    line-height:25px;
    text-align:center;
    padding:10px;    
	position:absolute !important;
	bottom:0;
	width:100% !important;
}