.fullscreen-enabled {
  background: linear-gradient( to right, hsl(200, 100%, 40%), hsl(191, 100%, 43%) );
}
.align-center {
    text-align:center;
}
.pdl-10 {
    padding-left:10px;
}
.pd-10 {
    padding:10px;
}
.pd-2 {
    padding:2px;
}
.brandname {
    font-size:28px;
    text-align:center;
    height: 65px;
    margin-left: 0px;
    padding-left:20px;
    margin-right: 0px;
    vertical-align: middle;
    padding-top:12px;
    font-weight: bold;
    color: #FFFFFB;
}
.text-center {
    text-align:center !important;
}
