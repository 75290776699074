.fullscreen-enabled {
  background: linear-gradient( to right, hsl(200, 100%, 40%), hsl(191, 100%, 43%) );
}
.align-center {
    text-align:center;
}
.pdl-10 {
    padding-left:10px;
}
.pd-10 {
    padding:10px;
}
.brandname {
    font-size:28px;
    text-align:center;
    height: 65px;
    margin-left: 0px;
    padding-left:20px;
    margin-right: 0px;
    vertical-align: middle;
    padding-top:12px;
    font-weight: bold;
    color: #FFFFFB;
}
.orgname {
    font-size:28px;
    text-align:center;
    height: 65px;
    margin-left: 0px;
    padding-left:20px;
    margin-right: 0px;
    vertical-align: middle;
    padding-top: 8px;
    font-weight: bold;
    color: #FFFFFB;
}


@media only screen and (max-width: 480px) {
    .orgname  {
        font-size:12px !important;
    }
    .brandname {
        font-size: 13px !important;
    }
  }

  .clientinternetspeed {
    font-size:11px;
    color:#FFF;
    margin-top:5px;
  }