.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 400px));
  justify-content: center;
  column-gap: 1rem;
  row-gap: 1rem;
}
.progress {
  height:18px !important;
}
.progress-bar-client-layer {
  margin-top:10px !important;
  padding:10px;
  background: #00b4dbac !important;
}
.progress-bar-client {
  height:18px !important;
  top:80px;
  position:absolute !important;
  z-index:999999 !important;
  width:95% !important;
}
.overlay {
  z-index:999999;
  border: solid 2px #de3c50e6;
  width:100% !important;
  height:100% !important;
}
.align-center {
	text-align:center !important;
}
.align-right {
	text-align:right !important;
}
.overlay_outer {
    z-index:999;
}

.overlay_client_outer {
  position:absolute;
  z-index:999;
}

.overlay_client {
  z-index:999;
  border: solid 2px #de3c50e6;
  width:100% !important;
  height:100% !important;
}

#first_video {
  width:auto !important;
  position: relative !important;
  margin-top: 0px !important;
}

.client_faceimage {
  width:100% !important;
  position: relative !important;
  height:190px;
  transition: opacity 0.5s;
  background:#FFF;
  margin: 0px 0px 5px 5px !important;
  text-align:center !important;
  padding:5px;
}

.host_videolayer {
  width:100% !important;
  position: relative !important;
  height:210px;
  transition: opacity 0.5s;
  background:#FFF;
  margin: 0px 0px 5px 5px !important;
  text-align:center !important;
  padding:5px;
}

.pan_image {
  position: relative !important;
  height:'auto';
  transition: opacity 0.5s;
  margin: 0px 0px 5px 5px !important;
  text-align:center !important;
  padding:5px;	
}
.mgl-10 {
  margin-left:10px;
}
.captured_image {
  padding: 4px;
  line-height: 1.42857143;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius:0px;
  -webkit-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  display: inline-block;
  max-width: 100%;
  max-height:250px !important;
}

.instruction_layer {
  width:100% !important;
  position: relative !important;
  height:auto !important;
  transition: opacity 0.5s;
  background:#FFF;
  margin: 0px 0px 5px 5px !important;
  text-align:center !important;
  padding:5px;
  min-height:300px;
  max-height:300px;
  overflow-y:scroll;
}
.internetspeed {
  font-size:11px;
}
.bandwidth_layer {
  width:100% !important;
  position: relative !important;
  height:auto !important;
  transition: opacity 0.5s;
  background:#FFF;
  margin: 0px 0px 5px 5px !important;
  text-align:center !important;
  padding:5px;
  min-height:100px;
}
.fontsize-11 {
  font-size:11px;
}
.fontsize-12 {
  font-size:12px;
}
.messagelayer {
  padding:15px;
  background: #8f2d2f !important;
  width:100%;
  margin:0 auto !important; 
  color:#FFF !important;
}

.aadhar_upload_header {
  padding:5px;
  background: #8f2d2f !important;
  width:100%;
  margin:0 auto !important; 
  color:#FFF !important;
}

.clientinfo_layer {
  padding:10px;
  background: #8f2d2f !important;
  width:100%;
  margin:0 auto !important; 
  color:#FFF !important;
  text-align: center;
  font-size: 13px;
}

.input_msg {
  font-family: Gilroy-light;
  font-weight: bold;
  margin-top: 1rem;
}

.align-center {
  text-align:center;
}

.align-left {
  text-align:left;
}

.card {
  background-color: #fefefe;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius:0px;
  border:none !important;
  border-image-slice: 1;
  padding: 10px 0;
}

.card2 {
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center;
  padding: -2px 0 0 0;
}

.video-avatar-container {
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  max-height: 100%;
}
.clientvideo_container {
  background:#FFF;
  padding:3px;
  align-items: center;
  justify-content: center;
  position: relative;
  height:98.5%;
  width:103%;
  max-height:520px;
}

.video-avatar-container_receiver {
    height:100%;
    width:100%;
    transition: opacity 0.5s;
    background: -webkit-linear-gradient(top,#074055 0%,#030D10 100%);
    display: table;
}

.video-avatar-container_receiver video {
  width:100%;
  height:100%;
  display: table-cell;
  vertical-align: middle;
  max-height:510px !important;
}

.avatar_image {
  position:absolute !important;
  z-index:999 !important;
  display: table-cell;
  vertical-align: middle;
  top:30px;
  left:50px;
}

.iconsDiv {
  justify-content: space-evenly;
  align-items: center;
  margin-top: 0.5rem;
  position:absolute !important;
 /*z-index:9999999 !important;
  background-color: aqua; */
}

.iconsDiv > div {
  padding-bottom: 100;
  --size: 2.8rem;
  width: var(--size);
  height: var(--size);
  display: flex;
  align-items: center;
  justify-content: center;
}
.icons {
  background-color: #fdfdfd;
  border-radius: 50%;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.34);
  transition: box-shadow 0.3s ease-in-out;
  font-size: 1.4rem;
}
.icons > * {
  width: 20px;
  height: 20px;
}
.icons:active {
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.19);
}
.icons:hover {
  cursor: pointer;
  opacity: 0.8;
}
@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translate3d(0, -16px, 0);
  }
}
.bouncing-loader {
  display: flex;
  justify-content: center;
  margin: 5rem auto;
  border: 0px solid black;
}
.bouncing-loader > div {
  width: 16px;
  height: 16px;
  margin: 3rem 0.2rem;
  background: #fefefe;
  border-radius: 50%;
  animation: bouncing-loader 0.6s infinite alternate;
}
.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}
.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}
.img_illus {
  height: 15rem;
}
.chat_img_div {
  display: grid;
  place-items: center;
}
.msg_flex > div {
  background-color: #fdfdfd;
  border: 1px solid #bebebe;
  border-radius: 4px;
  padding: 0.4rem;
  margin-top: 0.4rem;
}
.msg_flex {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  max-height: 250px;
  overflow-y: scroll;
}
.msg_flex::-webkit-scrollbar {
  display: none;
}
.msg_sent {
  align-self: flex-end;
}
#no_border {
  border: none;
}

/* @media only screen and (max-width: 600px) {
  .grid {
    grid-template-columns: repeat(auto-fit, minmax(325px, 425px));
  }
  #video1 {
    grid-row: 2/3;
  }
  #video2 {
    grid-row: 1/2;
  }
  #video3 {
    grid-row: 1/2;
  }
} */
@media only screen and (max-width: 360px) {
  .iconsDiv > div {
    --size: 2.5rem;
  }
  .icons {
    font-size: 1rem;
  }
  .icons > * {
    width: 15px;
    height: 15px;
  }
}

.pd-5 {
  padding: 5px !important;
}
.pd-10 {
  padding:0px 10px 10px 10px;
}
.pdt-0 {
    padding-top:0px !important;
}
.mgt-5 {
  margin-top:5px;
}
.pd-20 {
  padding:20px !important;
}
.box {
  border: 1px solid #8f2d2f;
  min-height: 520px;
  max-height:520px;
}
.box .steps {
  border-bottom: 1px solid #ddd;
}
.box .steps ul {
  overflow: hidden;
}
.box .steps ul li {
  width:20% !important;
  text-align:center !important;
}
.box .steps ul li div {
  color: #8f2d2f !important;
  padding:8px 0 6px 30px;
  position: relative;
  background: #f5f5f5;
  width:100% !important;
  text-align:ceter;
}
.box .steps ul li div span {
  font-size: 13px;
}
.box .steps ul li:first-child div {
  padding-left: 15px;
}
.callerName  {
    color:#FFF;
    font-size:16px;
    font-weight:bold;
}
.box .steps ul li div::before {
  content: " ";
  border-top:20px solid transparent;
  border-bottom:20px solid transparent;
  border-left: 20px solid #ddd;
  position: absolute;
  top: 50%;
  margin-top: -20px;
  left: 100%;
  z-index: 1;
  margin-left: 1px;
}
.box .steps ul li div::after {
  content: " ";
  border-top:20px solid transparent;
  border-bottom:20px solid transparent;
  border-left: 20px solid #f5f5f5;
  position: absolute;
  top: 50%;
  margin-top: -20px;
  left: 100%;
  z-index: 2;
}
.box .steps ul li.done div {
  border-color: #2d608f !important;
  color: #fff !important;
  background: #2d608f !important;
}
.box .steps ul li.done div::after {
  border-left: 20px solid #2d608f;
}
.box .steps ul li.active div {
  border-color: #8f2d2f !important;
  color: #fff !important;
  background: #8f2d2f !important;
  text-align:ceter;
}
.box .steps ul li.active div::after {
  border-left: 20px solid #8f2d2f;
}
.box .step-component {
  padding:10px;
  min-height:360px !important;
  align-items: center;
  justify-content: center;
}
.tab-content {
  padding:10px;
}
.box .btn-component {
  margin-top:-50px;
  display: flex;
  justify-content: space-between;
  margin-bottom:10px;
}
.mgt-10 {
  margin-top:5px !important;
}
.mgt-5 {
  margin-top:5px !important;
}
.mgt-4 {
  margin-top:4px !important;
}
.mgt-3 {
  margin-top:3px !important;
}
.mgt-2 {
  margin-top:2px !important;
}
.tabs_layer {
  padding:0px !important;
  background-color: #fff;
}
.nav-tabs .nav-item .nav-link, .nav-tabs .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #FFF !important;
  background-color:#8f2d2f !important;
  border-color:#8f2d2f !important;
}
.mgt-15 {
  margin-top:15px !important;
}
.mgt-20 {
  margin-top:20px !important;
}
.mgb-30 {
  margin-bottom: 30px;
}
.form-label {
	font-weight:bold;
	margin-bottom:2px !important;
}
.errormessage {
	color:red;
	font-size:12px;
}
.main-window {
  padding-top: 80px;
}
.video-container-absolute {
  top:0;
  bottom: 0;
  width: 100% !important;
  height: 100% !important;
}

.video-container > div {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background: -webkit-linear-gradient(top,#074055 0%,#030D10 100%);
  background: -moz-linear-gradient(top,#074055 0%,#030D10 100%);
  background: -o-linear-gradient(top,#074055 0%,#030D10 100%);
  background: linear-gradient(top,#074055 0%,#030D10 100%); 
}

.video-container > div > #client_video {
  object-fit: cover;
  width:100% !important;
}

#host_video {
  position: absolute !important;
  bottom:5% !important;
  right:0 !important;
  width:25%;
  height:25%;
}

.card {
  padding:0px !important;
}

.card-header {
  width:100% !important;  
  font-weight:bold;  
  background: #8f2d2f !important;
  text-align:center;
  color:#FFF;
}

.card-body {
  width:100% !important;
}

.card-footer {
  width:100% !important;
  text-align:center;
}

.options_layer {
  /*position: absolute !important;
  bottom:60% !important;
  height:72px !important;*/
  width: 100% !important;
  text-align: center !important;
  z-index: 99999999999 !important;
}
.userImage {
    margin-top:20% !important;
}
.clientvideo-label {
    color:#FFF;
    font-size:22px;
    font-weight: bold;
    margin-top:5%;
}
.capturefacebtn {
    padding:5px 10px !important;
    margin-top:2px;
}
.show {
	display:block !important;
}
.hide {
	display:none !important;
}
.float-right {
	float:right !important;
}
.width-100 {
  width:100% !important;
}
.zoombtn {
   margin-top:-80px;
   z-index:999999999999 !important;
}
.img-thumbnail {
	background:none !important;
}
.mgt--20 {
  margin-top:-20px !important;	
}
.nsdl_layer {
  padding:20px 0px 10px 0px;
}
.nsdl-header {
	font-size:14px;
	font-weight:bold;
}
.btn {
	font-size:14px !important;
}
.min-450 {
	min-height:520px !important;
}
.noborder {
   border:none !important;
}
.mgt-100 {
  margin-top:100px !important;
}
.btn-danger {
  background-color: #8f2d2f !important;
  border-color: #8f2d2f !important;
}
.btn-recording {
    padding:15px 20px;
    font-size:18px !important;
    background-color: #8f2d2f !important;
    border-color: #8f2d2f !important;
}
.float-left {
  float:left;
}
.float-right {
  float:right;
}
.ant-modal-title {
  text-align: center;
  font-weight: bold;
}
.font-10 {
  font-size:12px;
}
.text_white {
  color:#FFF !important;
}