.mainheader {
    position: relative;
    height: 70px;
    background: #8f2d2f !important;
}
.align-center {
    text-align:center;
}
.pdl-10 {
    padding-left:10px;
}
.pd-10 {
    padding:10px;
}
.brandname {
    font-size:28px;
    text-align:center;
    height: 65px;
    margin-left: 0px;
    padding-left:20px;
    margin-right: 0px;
    vertical-align: middle;
    padding-top:12px;
    font-weight: bold;
    color: #FFFFFB;
}
.orgname {
    font-size:25px;
    text-align: center;
    height: 65px;
    margin-left: 0px;
    margin-right: 0px;
    vertical-align: middle;
    padding-top: 8px;
    font-weight: bold;
    color: #FFFFFB;
}
.clientaddress {
    font-weight: normal;
    color: #FFFFFB;
    font-size:13px !important;
    text-align:center !important;
}
.login_user {
   color:#FFF !important;   
}
.timertext {
    font-size:20px;
    margin-bottom:5px;
}