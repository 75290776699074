.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 400px));
  justify-content: center;
  column-gap: 1rem;
  row-gap: 1rem;
}
.progress {
  height:18px !important;
}
.progress-bar-client-layer {
  margin-top:10px !important;
  padding:10px;
  background: #00b4dbac !important;
}
.progress-bar-client {
  height:18px !important;
  top:80px;
  position:absolute !important;
  z-index:999999 !important;
  width:95% !important;
}
.overlay {
  z-index:999999;
  border: solid 2px #de3c50e6;
  width:100% !important;
  height:100% !important;
}
.align-center {
	text-align:center !important;
}

.overlay_outer {
	position:absolute;
    z-index:999;
}

.overlay_client_outer {
  position:absolute;
  z-index:999;
}

.overlay_client {
  z-index:999;
  border: solid 2px #de3c50e6;
  width:100% !important;
  height:100% !important;
}

#first_video {
  width:100% !important;
  position: relative !important;
  margin-top: 0px !important;
  height :200px
}

.client-call-window {
  
  position: absolute !important;
  top:65px !important;
  bottom:25px !important;
  width: 100%;
  height: 100%; 
  overflow: hidden;
}

.client-call-window video {
 /* Make video to at least 100% wide and tall */
  min-width: 100%; 
  min-height: 100%; 
  /* Setting width & height to auto prevents the browser from stretching or squishing the video */
  width: auto;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.client_faceimage {
  width:100% !important;
  position: relative !important;
  height:170px;
  transition: opacity 0.5s;
  background:#FFF;
  margin: 0px 0px 5px 5px !important;
  text-align:center !important;
  padding:10px;
}

.messagelayer {
  padding:15px;
  background: #8f2d2f !important;
  width:100%;
  margin:0 auto !important; 
  color:#FFF !important;
}
.aadhar_upload_header {
  padding:5px;
  background: #8f2d2f !important;
  width:100%;
  margin:0 auto !important; 
  color:#FFF !important;
}
.clientinfo_layer {
  padding:10px;
  background: #8f2d2f !important;
  width:100%;
  margin:0 auto !important; 
  color:#FFF !important;
  text-align: center;
  font-size: 13px;
}
.input_msg {
  font-family: Gilroy-light;
  font-weight: bold;
  margin-top: 1rem;
}
.align-center {
  text-align:center;
}
.align-left {
  text-align:left;
}
.card {
  background-color: #fefefe;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 2px solid rgb(5, 117, 230, 1);
  border-image-slice: 1;
  padding: 10px 0;
}
.card2 {
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center;
  padding: -2px 0 0 0;
}
.avatar_image {
  position:absolute !important;
  z-index:999 !important;
  display: table-cell;
  vertical-align: middle;
  top:30px;
  left:50px;
}
.iconsDiv {
  justify-content: space-evenly;
  align-items: center;
  margin-top: 0.5rem;
  /* background-color: aqua; */
}
.iconsDiv > div {
  --size: 2.8rem;
  width: var(--size);
  height: var(--size);
  display: flex;
  align-items: center;
  justify-content: center;
}
.icons {
  background-color: #fdfdfd;
  border-radius: 50%;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.34);
  transition: box-shadow 0.3s ease-in-out;
  font-size: 1.4rem;
}
.icons > * {
  width: 20px;
  height: 20px;
}
.icons:active {
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.19);
}
.icons:hover {
  cursor: pointer;
  opacity: 0.8;
}
@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translate3d(0, -16px, 0);
  }
}
.bouncing-loader {
  display: flex;
  justify-content: center;
  margin: 5rem auto;
  border: 0px solid black;
}
.bouncing-loader > div {
  width: 16px;
  height: 16px;
  margin: 3rem 0.2rem;
  background: #fefefe;
  border-radius: 50%;
  animation: bouncing-loader 0.6s infinite alternate;
}
.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}
.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}
.img_illus {
  height: 15rem;
}
.chat_img_div {
  display: grid;
  place-items: center;
}
.msg_flex > div {
  background-color: #fdfdfd;
  border: 1px solid #bebebe;
  border-radius: 4px;
  padding: 0.4rem;
  margin-top: 0.4rem;
}
.msg_flex {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  max-height: 250px;
  overflow-y: scroll;
}
.msg_flex::-webkit-scrollbar {
  display: none;
}
.msg_sent {
  align-self: flex-end;
}
#no_border {
  border: none;
}

/* @media only screen and (max-width: 600px) {
  .grid {
    grid-template-columns: repeat(auto-fit, minmax(325px, 425px));
  }
  #video1 {
    grid-row: 2/3;
  }
  #video2 {
    grid-row: 1/2;
  }
  #video3 {
    grid-row: 1/2;
  }
} */
@media only screen and (max-width: 360px) {
  .iconsDiv > div {
    --size: 2.5rem;
  }
  .icons {
    font-size: 1rem;
  }
  .icons > * {
    width: 15px;
    height: 15px;
  }
}
.pd-5 {
  padding: 5px !important;
}
.pd-10 {
  padding:0px 10px 10px 10px;
}
.pdt-0 {
    padding-top:0px !important;
}
.mgt-5 {
  margin-top:5px;
}
.pd-20 {
  padding:20px !important;
}
.callerName  {
    color:#FFF;
    font-size:16px;
    font-weight:bold;
}
.tab-content {
  padding:10px;
}
.box .btn-component {
  display: flex;
  justify-content: space-between;
}
.mgt-10 {
  margin-top:5px !important;
}
.mgt-5 {
  margin-top:5px !important;
}
.mgt-4 {
  margin-top:4px !important;
}
.mgt-3 {
  margin-top:3px !important;
}
.mgt-2 {
  margin-top:2px !important;
}
.tabs_layer {
  padding:0px !important;
}
.nav-tabs .nav-item .nav-link, .nav-tabs .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #FFF !important;
  background-color:#8f2d2f !important;
  border-color:#8f2d2f !important;
}

.mgt-20 {
  margin-top:20px !important;
}
.mgb-30 {
  margin-bottom: 30px;
}

.main-window {
  padding-top: 80px;
}

.video-container-absolute {
  top:0;
  bottom: 0;
  width: 100% !important;
  height: 100% !important;
}

.video-container > div {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background: -webkit-linear-gradient(top,#074055 0%,#030D10 100%);
  background: -moz-linear-gradient(top,#074055 0%,#030D10 100%);
  background: -o-linear-gradient(top,#074055 0%,#030D10 100%);
  background: linear-gradient(top,#074055 0%,#030D10 100%); 
}

.video-container > div > #client_video {
  object-fit: cover;
  width:100% !important;
}

#host_video {
  position: absolute !important;
  bottom:5% !important;
  right:0 !important;
  width:25%;
  height:25%;
}

.card {
  padding:0px !important;
}

.card-header {
  width:100% !important;
  background: #074055;
  font-weight:bold;
}

.card-body {
  width:100% !important;
}

.card-footer {
  width:100% !important;
  text-align:center;
}

.options_layer {
  /*position: absolute !important;
  bottom:60% !important;
  height:72px !important;*/
  width: 100% !important;
  text-align: center !important;
  z-index: 99999999999 !important;
}
.userImage {
    margin-top:20% !important;
}
.clientvideo-label {
    color:#FFF;
    font-size:22px;
    font-weight: bold;
    margin-top:5%;
}
.show {
	display:block !important;
}
.hide {
	display:none !important;
}
._loading_overlay_wrapper {
	position: absolute !important;
	top:0px !important;
	bottom:0px !important;
	left:0px !important;
	right:0px !important;
}
.disconnectlayer {
  background-color: #FFF !important;
  text-align:left;
	position:absolute !important;
	top:50% !important;
	right:0;
	bottom:0;
	left:0;
	height:100% !important;
}