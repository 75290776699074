.mgb-50 {
    margin-bottom: 50px !important;
}
.overflow_y {
	overflow-y:scroll !important;
}
.mgt-20 {
    margin-top:20px !important;
}
.mgr-10 {
    margin-right:10px;
}
.mgr-5 {
    margin-right:5px;
}