.mgb-50 {
    margin-bottom: 50px !important;
}
.overflow_y {
	overflow-y:scroll !important;
}

.a2formlayer {
    background-color: #FFF !important;
    text-align:left;
	position:absolute !important;
	top:20% !important;
	right:0;
	bottom:0;
	left:0;
	height:100% !important;
}
.card_header {
	background: #8f2d2f !important;
	text-align:center;
	color:#FFF;
}
.card_body {
	padding:5px;
}
.table-bordered {
  border: 1px solid #000;
  width:100%;
}
.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
  border: 1px solid #000;
  padding:5px;
}
.width-40 {
	width:40% !important;
}

.clienthangupbtn {
	line-height: 1.5715;    
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;    
    box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-user-select: none;
    -moz-user-select: none;    
    user-select: none;    
    height: 32px;
    padding:7px 30px;
    font-size: 14px;
    border-radius: 2px;
    color: rgba(0, 0, 0, 0.85);    
    border-color: #d9d9d9;
	background-color: rgb(250, 30, 14) !important;
    color: #000 !important;
    font-weight: bold;
    margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	color:#000 !important;
}